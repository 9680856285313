import { format } from "date-fns";
import { Member } from "../Types/MemberTypes";
import { CartURL } from "./const-data";

export const getInitials = (name: String, surname: String) => {
  return name && surname
    ? name.charAt(0).toUpperCase() + surname.charAt(0).toUpperCase()
    : "";
};

export const currencyFormat = (value: number | bigint) =>
  new Intl.NumberFormat("de-DE", {
    style: "currency",
    currency: "EUR",
  }).format(value);

export const getStepItemStatus = (
  index: number,
  currentStep: number,
  member: Member
) => {
  if (index === currentStep) {
    return "processing";
  } else if (isMemberCompleted(member)) {
    return "completed";
  }
  return "waiting";
};

export const getDisplayedItemsStartIndex = (
  currentStep: number,
  items: any[]
) => {
  if (currentStep === 0) {
    return 0;
  }
  if (currentStep + 2 < items.length - 1) {
    return currentStep - 1;
  }
  return items.length - 4;
};

export const getAge = (birthDate: Date) => {
  const age: number =
    new Date(Number(new Date()) - Number(new Date(birthDate))).getFullYear() -
    1970;

  return age;
};

export const formatSkillLevel = (skillLevelIdentifier: string | undefined) => {
  return skillLevelIdentifier
    ? skillLevelIdentifier.charAt(0).toUpperCase() +
        skillLevelIdentifier.slice(1).split("_").join(" ")
    : "";
};

export const isDayDisabled = (day: Date, allowedDates: Date[]) => {
  return !allowedDates.some((disabledDay) => {
    const currentDate = day.toDateString();
    const disabledDate = disabledDay.toDateString();
    return currentDate === disabledDate;
  });
};

export const toDateArgument = (date: string) => {
  return date.split(".").reverse().join("-");
};

export const dateFormatter = (date: Date | undefined) => {
  return date ? format(date, "yyyy-MM-dd") : undefined;
};

export const dateToBackendFormat = (date: string | undefined) => {
  return date + "T00:00:00+00:00";
};

export const formatTime = (timeString: string) => {
  const regex = /^(\d{2}):(\d{2}):\d{2}$/;
  const match = timeString.match(regex);

  if (!match) {
    throw new Error("Invalid time format. Expected HH:MM:SS");
  }

  const [, hours, minutes] = match;

  return `${hours}:${minutes}`;
};

export const isValidDateString = (date?: string | null): boolean => {
  if (!date) {
    return false;
  }
  return !isNaN(new Date(date).getDate());
};

export const isMemberCompleted = (member: Member) => {
  const { sport_type, date_range, skill_level, time } = member;

  if (
    sport_type &&
    skill_level &&
    time?.uuid &&
    date_range?.begin_date &&
    date_range?.end_date
  ) {
    return true;
  }
  return false;
};

export const capitalize = (string: string) => {
  const words = string
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());
  return words.join(" ");
};

export const getTotalTeamCost = (members: Member[]) => {
  let totalCost = 0;
  members.forEach((member) => {
    if (member.cost) {
      totalCost += member.cost;
    }
  });
  return totalCost;
};

export const getLastAllowedBirthdate = () => {
  return new Date(new Date().setFullYear(new Date().getFullYear() - 3));
};

export const getDatesInRange = (fromDate: Date, toDate: Date) => {
  const date = new Date(fromDate.getTime());
  const dates: Date[] = [];

  while (date <= toDate) {
    dates.push(new Date(date));
    date.setDate(date.getDate() + 1);
  }
  return dates;
};

export const createCartItem = (product: any, member: Member) => {
  const item = {
    productId: product.id,
    quantity: 1,
    payload: {
      participant: {
        firstName: member.first_name,
        lastName: member.last_name,
        dateOfBirth: dateToBackendFormat(member.birth_date),
      },
      saProductUuid: product.saProductUuid,
      skillLevelUuid: member.skill_level?.uuid,
      durationInDays: member.course_duration,
      startDate: dateToBackendFormat(member.date_range?.begin_date),
      sessionUuid: product.sessionUuid,
      priceListUuid: product.priceListUuid,
      startTimeUuid: member.time?.uuid,
    },
    lineItemType: "sa_product",
  };

  return item;
};

export const createCrossSellingCartItem = (product: any, member: Member) => {
  const item = {
    productId: product.id,
    quantity: 1,
    payload: {
      participant: {
        firstName: member.first_name,
        lastName: member.last_name,
        dateOfBirth: dateToBackendFormat(member.birth_date),
      },
      saProductUuid: product.saProductUuid,
      durationInDays: member.course_duration,
      startDate: dateToBackendFormat(member.date_range?.begin_date),
      sessionUuid: product.sessionUuid,
      priceListUuid: product.priceListUuid,
    },
    lineItemType: "sa_product",
  };

  return item;
};

export const getCartItems = (members: Member[]) => {
  const cartItems: any[] = [];
  members.forEach((member) => {
    cartItems.push(createCartItem(member.product, member));
    if (member.cross_selling_products?.length) {
      member.cross_selling_products.forEach((crossSellingProduct) =>
        cartItems.push(createCrossSellingCartItem(crossSellingProduct, member))
      );
    }
  });
  return cartItems;
};

export const goToShoppingCartPage = (token: string) => {
  const fullURL = `${process.env.REACT_APP_BASE_URL}${CartURL}${token}`;
  window.open(fullURL, "_parent");
};

export const calculateCourseTimes = (time: any) => {
  if (time.timeTwoTo) {
    return {
      beginTime: formatTime(time.timeOneFrom),
      endTime: formatTime(time.timeTwoTo),
    };
  }

  return {
    beginTime: formatTime(time.timeOneFrom),
    endTime: formatTime(time.timeOneTo),
  };
};
