import {
  ApolloProvider,
  ApolloClient,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import CheckIn from "../Pages/CheckIn";
import TeamDetails from "../Pages/TeamDetails";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// set languageId based on current language later
const languageIds = {
  de: "2fbb5fe2e29a4d70aa5854ce7ce3e20b",
  en: "01909191d47271bf9e8aa318577cbba5",
};

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_BASE_URL_GRAPHQL,
});

const authLink = setContext((_, { headers }) => {
  // temporary force language to be german until sales channel language english is available
  /* const [lang] = i18n.language.split("-");
  
  const currentLanguage = (
    ["de", "en"].includes(lang) ? lang : "en"
  ) as keyof typeof languageIds; */

  const currentLanguage = "de";

  return {
    headers: {
      "sw-access-key": process.env.REACT_APP_SW_ACCESS_KEY,
      "sw-language-id": languageIds[currentLanguage],
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

function App() {
  return (
    <>
      <ApolloProvider client={client}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<CheckIn />}></Route>
            <Route path="/TeamDetails" element={<TeamDetails />}></Route>
          </Routes>
        </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;
